"use client";
import { getAnnotatedNotes, getFretboardNotes, getTriadNotes, guitarTunings, triads, annotateNotes, scales, getNotes, notes, getAdjacentFifths, triadNames } from '../../logic';
import { useEffect, useState } from 'react';
import { zip } from 'lodash';
export default function Keytones() {
    var _a = useState(guitarTunings.Standard.join(',')), tuning = _a[0], setTuning = _a[1];
    var _b = useState('A'), rootNote = _b[0], setRootNote = _b[1];
    var _c = useState('minor'), triad = _c[0], setTriad = _c[1];
    var _d = useState('A'), triadRoot = _d[0], setTriadRoot = _d[1];
    var _e = useState(22), frets = _e[0], setFrets = _e[1];
    var _f = useState('Minor Pentatonic'), scale = _f[0], setScale = _f[1];
    var _g = useState([]), fretboardDisplay = _g[0], setFretboardDisplay = _g[1];
    useEffect(function () {
        var _a;
        var selectedScale = scales.find(function (_a) {
            var name = _a.name;
            return name === scale;
        });
        var triad = (_a = selectedScale === null || selectedScale === void 0 ? void 0 : selectedScale.triad) !== null && _a !== void 0 ? _a : 'minor';
        setTriad(triad);
        setTriadRoot(rootNote);
    }, [scale, rootNote]);
    useEffect(function () {
        var _a;
        var selectedScale = scales.find(function (_a) {
            var name = _a.name;
            return name === scale;
        });
        var triadIntervals = triads[triad];
        var fretboard = getFretboardNotes(tuning.split(','), frets + 1);
        var triadNotes = getTriadNotes(triadRoot, triadIntervals);
        var annotated = fretboard.map(function (notes) { return getAnnotatedNotes(notes); });
        // Add triad keytones
        annotated = annotated.map(function (notes) { return annotateNotes(notes, triadNotes, function (match) {
            var _a;
            return ({
                triad: {
                    name: "".concat(triad, " - ").concat(rootNote),
                    position: ((_a = {},
                        _a[triadNotes[0]] = { name: 'Root', colour: 'red' },
                        _a[triadNotes[1]] = { name: '3rd', colour: 'green' },
                        _a[triadNotes[2]] = { name: '5th', colour: 'blue' },
                        _a[triadNotes[3]] = { name: '7th', colour: 'purple' },
                        _a)[match]),
                }
            });
        }); });
        // Add scale
        var scaleNotes = getNotes(rootNote, (_a = selectedScale === null || selectedScale === void 0 ? void 0 : selectedScale.intervals) !== null && _a !== void 0 ? _a : []);
        annotated = annotated.map(function (notes) { return annotateNotes(notes, scaleNotes, function (match) { return ({
            'ScaleNote': true,
        }); }); });
        setFretboardDisplay(annotated.reverse());
    }, [frets, scale, tuning, rootNote, triadRoot, triad]);
    var circleOfFifths = getAdjacentFifths(rootNote, 5);
    var circleOfFifthsCore = getAdjacentFifths(rootNote, 1);
    var fretboard = zip.apply(void 0, fretboardDisplay).map(function (strings, i) {
        var marker = [3, 5, 7, 9, 15, 17, 19].includes(i)
            ? '⬤'
            : i === 12
                ? '⬤⬤'
                : i === 0 ? '◯' : '';
        return {
            index: i,
            marker: marker,
            strings: strings,
        };
    });
    return (<main className="flex min-h-screen flex-col items-center p-10">

      <h2 className='mb-4'>Options</h2>
      <div className='grid grid-cols-2 pb-10 space-y-2'>
        <label htmlFor='tuning'>Tuning</label>
        <input className='px-1 rounded-sm text-black bg-white' id='tuning' value={tuning} pattern='^(A|A#|B|C|C#|D|D#|E|F|F#|G|G#){1}(,(A|A#|B|C|C#|D|D#|E|F|F#|G|G#))*$' onChange={function (e) { return setTuning(e.target.value); }}/>

        <label htmlFor='frets'>Frets</label>
        <input className='px-1 rounded-sm text-black bg-white' type='number' id='frets' value={frets} onChange={function (e) { return setFrets(parseInt(e.target.value)); }}/>

        <label htmlFor='scale'>Scale</label>
        <select className='px-1 rounded-sm text-black bg-white' id='scale' value={scale} onChange={function (e) { return setScale(e.target.value); }}>
          {scales.map(function (_a) {
            var name = _a.name;
            return (<option key={name} value={name}>{name}</option>);
        })}
        </select>

        <label htmlFor='rootNote'>Root Note</label>
        <select className='px-1 rounded-sm text-black bg-white' id='rootNote' value={rootNote} onChange={function (e) { return setRootNote(e.target.value); }}>
          {notes.map(function (note) { return (<option key={note} value={note}>{note}</option>); })}
        </select>

        <label htmlFor='triad'>Triad</label>
        <select className='px-1 rounded-sm text-black bg-white' id='triad' value={triad} onChange={function (e) { return setTriad(e.target.value); }}>
          {triadNames.map(function (name) { return (<option key={name} value={name}>{name}</option>); })}
        </select>

        <label htmlFor='triadRoot'>Triad Root</label>
        <select className='px-1 rounded-sm text-black bg-white' id='triadRoot' value={triadRoot} onChange={function (e) { return setTriadRoot(e.target.value); }}>
          {notes.map(function (note) { return (<option key={note} value={note}>{note}</option>); })}
        </select>
      </div>

      <h2 className='mb-4 text-center'><div>Circle of Fifths</div><div className='text-xs'>(click to change root note)</div></h2>
      <div className='border-l border-gray-400 mb-6'>
        <div>
          {circleOfFifths.major.map(function (note) { return (<div key={note} className={"\n                hover:bg-gray-200 cursor-pointer select-none\n                inline-block text-center w-20 border-t border-r border-gray-400\n                ".concat(!circleOfFifthsCore.major.includes(note) ? 'text-gray-400 hidden lg:inline-block' : '', "\n              ")} onClick={function () { return setRootNote(note); }}>
              {note}
            </div>); })}
        </div>
        <div>
          {circleOfFifths.minor.map(function (note) { return (<div key={note} className={"\n              hover:bg-gray-200 cursor-pointer select-none\n                inline-block text-center w-20 border-t border-r border-b border-gray-400\n                ".concat(!circleOfFifthsCore.minor.includes(note) ? 'text-gray-400 hidden lg:inline-block' : '', "\n              ")} onClick={function () { return setRootNote(note); }}>
              {note}m
            </div>); })}
        </div>
      </div>

      <h2 className='mb-4'>Fretboard</h2>
      <div className='mb-4'>
        <span className='text-red-600 m-2'>Root</span>
        <span className='text-green-600 m-2'>Third</span>
        <span className='text-blue-600 m-2'>Fifth</span>
      </div>
      <div className='grid grid-flow-row grid-cols-5 sm:grid-cols-12 lg:grid-flow-col-dense gap-y-10 divide-x divide-gray-400'>
        {fretboard.map(function (_a) {
            var index = _a.index, strings = _a.strings, marker = _a.marker;
            return (<div key={index} className={"\n              grid grid-cols-1 gap-0 align-middle\n              ".concat(index === 0 ? 'border-r-2' : '', "\n            ")}>
            <div className='min-w-[100%] w-12 inline-block text-center border-b border-gray-400 text-gray-400 text-xs'>
              <div className=''>{index}</div>
              <div className='h-4'>{marker}</div>
            </div>
            {strings.map(function (fret, i) {
                    var _a, _b, _c;
                    return (<div key={i} className='h-9 min-w-[100%] inline-block text-center border-gray-400 border-b p-1'>
                <div className={"".concat((fret === null || fret === void 0 ? void 0 : fret.attributes.ScaleNote) ? 'rounded-xl border border-gray-500 bg-gray-300' : '', "\n                      ").concat(((_c = (_b = (_a = fret === null || fret === void 0 ? void 0 : fret.attributes) === null || _a === void 0 ? void 0 : _a.triad) === null || _b === void 0 ? void 0 : _b.position) === null || _c === void 0 ? void 0 : _c.colour)
                            ? "text-".concat(fret === null || fret === void 0 ? void 0 : fret.attributes.triad.position.colour, "-600")
                            : "".concat((fret === null || fret === void 0 ? void 0 : fret.attributes.ScaleNote) ? '' : 'text-gray-500'))}>
                  {fret === null || fret === void 0 ? void 0 : fret.note}
                </div>
              </div>);
                })}
          </div>);
        })}
      </div>

    </main>);
}
