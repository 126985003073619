import { merge } from 'lodash';
export var notes = ['A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#'];
export var triadNames = ['major', 'minor', 'dim', 'aug'];
export var scaleNames = [
    'Minor Pentatonic', 'Major Pentatonic',
    'Natural Major', 'Natural Minor', 'Harmonic Minor',
    'Major Arpeggio', 'Minor Arpeggio', 'Diminished Arpeggio', 'Augmented Arpeggio'
];
export var guitarTunings = {
    'Standard': ['E', 'A', 'D', 'G', 'B', 'E'],
    'Drop D': ['D', 'A', 'D', 'G', 'B', 'E'],
    'Ukulele': ['G', 'C', 'E', 'A'],
};
export var triads = {
    'major': [0, 4, 7, 11],
    'minor': [0, 3, 7, 10],
    'dim': [0, 3, 6, 10],
    'aug': [0, 4, 8, 10],
};
export var scales = [
    {
        name: 'Minor Pentatonic',
        intervals: [0, 3, 5, 7, 10],
        triad: 'minor',
    },
    {
        name: 'Major Pentatonic',
        intervals: [0, 2, 4, 7, 9],
        triad: 'major'
    },
    {
        name: 'Natural Major',
        intervals: [0, 2, 4, 5, 7, 9, 11],
        triad: 'major',
    },
    {
        name: 'Natural Minor',
        intervals: [0, 2, 3, 5, 7, 8, 10],
        triad: 'minor',
    },
    {
        name: 'Harmonic Minor',
        intervals: [0, 2, 3, 5, 7, 8, 11],
        triad: 'minor',
    },
    {
        name: 'Major Arpeggio',
        intervals: triads.major,
        triad: 'major',
    },
    {
        name: 'Minor Arpeggio',
        intervals: triads.minor,
        triad: 'minor',
    },
    {
        name: 'Diminished Arpeggio',
        intervals: triads.dim,
        triad: 'dim',
    },
    {
        name: 'Augmented Arpeggio',
        intervals: triads.aug,
        triad: 'aug',
    },
];
var add12 = function (value) {
    var result = value;
    while (result < 0) {
        result += 12;
    }
    return result;
};
export var getNotes = function (root, intervals) {
    var rootValue = notes.indexOf(root);
    return intervals.map(function (interval) { return notes[(rootValue + (interval < 0 ? add12(interval) : interval)) % 12]; });
};
export var fifthsIntervals = Array.from({ length: 12 }, function (_, i) { return i * 7; });
export var circleOfFifths = {
    major: getNotes("C", fifthsIntervals),
    minor: getNotes("A", fifthsIntervals),
};
export var getAdjacentFifths = function (majorNote, numAdjacent) { return ({
    major: getNotes(majorNote, Array.from({ length: (numAdjacent * 2) + 1 }, function (_, i) { return (i - numAdjacent) * 7; })),
    minor: getNotes(majorNote, Array.from({ length: (numAdjacent * 2) + 1 }, function (_, i) { return ((i - numAdjacent) * 7) - 3; })),
}); };
export var getTriadNotes = function (root, intervals) { return getNotes(root, intervals); };
export var getFretboardNotes = function (tuning, numFrets) {
    var frets = Array.from({ length: numFrets }, function (_, i) { return i; });
    return tuning.map(function (startNote) { return getNotes(startNote, frets); });
};
export var getAnnotatedNotes = function (notes) { return notes.map(function (note) { return ({ attributes: {}, note: note }); }); };
export var annotateNotes = function (notes, targetNotes, getAttributes) {
    return notes.reduce(function (acc, cur) {
        var match = targetNotes.find(function (note) { return cur.note === note; });
        var attributes = match
            ? merge(cur.attributes, getAttributes(match))
            : cur.attributes;
        acc.push({
            note: cur.note,
            attributes: attributes,
        });
        return acc;
    }, []);
};
